import React from "react";
import styles from "../../css/forms/Divider.module.scss";
import { PropTypes } from "prop-types";

const Divider = ({ isHidden = false, customStyles = {} }) => {
	if (isHidden) {
		return null;
	}
	return <hr className={styles.Divider} style={customStyles} />;
};

export default Divider;

Divider.defaultProps = {
	isHidden: false,
	customStyles: {},
};

Divider.propTypes = {
	isHidden: PropTypes.bool,
	customStyles: PropTypes.object,
};
