import React from "react";
import styles from "../../css/tabs/FullPageTabPanels.module.scss";
import { PropTypes } from "prop-types";

// CONTAINS SHOWN/HIDDEN TAB CONTENT SECTIONS //

const FullPageTabPanels = ({ activeIndex, children }) => {
	const withActiveIndex = React.Children.map(children, (child, i) => {
		return React.cloneElement(child, {
			activeIndex: activeIndex,
			tabIndex: i,
		});
	});
	return (
		<section className={styles.FullPageTabPanels}>{withActiveIndex}</section>
	);
};

export default FullPageTabPanels;

FullPageTabPanels.defaultProps = {};

FullPageTabPanels.propTypes = {
	activeIndex: PropTypes.number,
	children: PropTypes.any.isRequired,
};
